.image-group-container {
  position: absolute;
  right: 48px;
  top: 0;
  bottom: 0;
  width: 230px;
  height: 100%;

  @include media-breakpoint-down(sm) {
    //background-color: orange !important;
    position: relative;
    height: 250px;
    left: 0;
    width: 100%;
  }
}

.image-group-sub-container {
  position: absolute;
  left: 0;
  top: 50%;
  right: 0;
  width: 100%;
  height: 80%;
  //background-color: purple;
  transform: translate(0, -50%);

  @include media-breakpoint-down(sm) {
    position: relative;
    width: 100%;
    margin: 0 auto;
    right: auto;
  }
}
.inspection_image_container{
  position: relative;
  .inspection_image {

    z-index :1;
    max-width: 300px;
    max-height: 300px;
  }
  button {
    position: absolute;
    top: 0;
    left: 5;
  }
}

.detail-tab-bar {
  box-shadow: 0px 0px 10px 1px rgba(231, 9, 9, 0.1);
}
.detail-tab-content {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
}

.sales-image-flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  border: 1px solid silver;
  -ms-box-orient: horizontal;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -moz-flex;
  display: -webkit-flex;
  display: flex;
  &.wrap {
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .flex-item {
    background: $custom-primary-100;
    width: 100px;
    height: 100px;
    line-height: 100px;
    margin: 10px;
    padding-top: 0px;
    padding-left: 3px;
    padding-bottom: 3px;
    padding-right: 3px;
    color: white;
    text-align: center;
    align-items:  center;
  }
  img {
    max-height: 100%;
  }
}

.inspection-title{
  text-align: right;
}
@media (max-device-width: 800px) {
  .inspection-title {
    text-align: left;
  }
}