// Outline
.outline-none {
  outline: none;
  &:focus {
    outline: none;
  }
}
// Removes shadow
.no-shadow-btn {
  &:focus {
    box-shadow: none !important;
  }
}

.navbar-light .navbar-toggler {
  color: #fd5109;
  border-color: transparent !important;
}

// Height

.height-72 {
  height: 72px;
}

.full-height {
  height: 100%;
}

.full-height-max {
  max-height: 100%;
}

.height-220-260 {
  height: 220px;
  @media (max-width: 960px) {
    height: 260px;
  }
}

// Border radius
.rounded-10 {
  border-radius: 10px;
}

.rounded-20 {
  border-radius: 20px;
}

.rounde-10-bottom {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

// Z-index

.z-index-to-1000 {
  z-index: 1000;
}

.z-index-to-1050 {
  z-index: 1050;
}

.z-index-to-1100 {
  z-index: 1100;
}

.z-index-to-1200 {
  z-index: 1200;
}

.z-index-to-5 {
  z-index: 5;
}

.z-index-to-2 {
  z-index: 2;
}

.z-index-to-1 {
  z-index: 1;
}

.z-index-1 {
  z-index: -1;
}

// Width
.width-150 {
  width: 150px;
}

.max-width-220 {
  max-width: 220px;
}

.max-width-240 {
  max-width: 240px;
}

.max-width-260 {
  max-width: 260px;
}

.max-width-280 {
  max-width: 280px;
}

.max-width-340 {
  max-width: 340px;
}

.max-width-360 {
  max-width: 360px;
}

.max-width-460 {
  max-width: 360px;
}

.max-width-540 {
  max-width: 540px;
}

.max-width-620 {
  max-width: 620px;
}

.max-width-750 {
  max-width: 750px;
}

.min-width-72 {
  min-width: 72px;
}

.width-md-80-p {
  @media (min-width: 768px) {
    width: 80%;
    margin: 0 auto;
  }
}

.width-80 {
  width: 80%;
}

// Height
.height-100 {
  height: 100%;
}

.height-100vh {
  height: 100vh;
}

.min-height-180 {
  min-height: 180px;
}

.min-height-200 {
  min-height: 200px;
}

.min-height-220 {
  min-height: 220px;
}

// Cursor
.cursor-pointer {
  cursor: pointer;
}

// Hiding
.hide-on-mobile-nav {
  @media (max-width: 991.98px) {
    display: none !important;
  }
}

// Hiding
.hide-on-non-mobile-nav {
  @media (min-width: 992x) {
    display: none !important;
  }
}


.hide-on-non-mobile { display: none; }
@media screen and (max-width: 991.98px){
  .hide-on-non-mobile { display: inline-block;}
}


.hide-section {
  display: none;
}

// sizes
.box-size-72 {
  height: 72px;
  width: 72px;
}

.box-size-64 {
  height: 64px;
  width: 64px;
}
.box-size-30 {
  height: 30px;
  width: 30px;
}

.box-size-20 {
  height: 20px;
  width: 20px;
}

// Animations
.transition-all {
  transition: all 0.4s ease-out;
}

//Letter spacing
.letter-spacing-1 {
  letter-spacing: 1px;
}

.letter-spacing-sm {
  letter-spacing: 0.6px;
}

// Margin
// Padding
.pt-6 {
  padding-top: 3.5rem;
}

.pt-7 {
  padding-top: 4rem;
}

.pt-8 {
  padding-top: 4.5rem;
}

.pt-9 {
  padding-top: 5rem;
}

.pt-10 {
  padding-bottom: 6rem;
}

.pb-6 {
  padding-bottom: 3.5rem;
}

.pb-7 {
  padding-bottom: 4rem;
}

.pb-8 {
  padding-bottom: 4.5rem;
}

.pb-9 {
  padding-bottom: 5rem;
}

.pb-10 {
  padding-bottom: 6rem;
}

// Faded dot
.faded-dot-item {
  height: 50px;
  width: 50px;
  position: absolute;
  left: -25px;
  top: -25px;
  background-color: rgba(253, 81, 9, 1);
  filter: blur(8px);
  -webkit-filter: blur(8px);
  border-radius: 50%;
  //box-shadow: 0px 5px 38px 30px rgba(253, 81, 9, 0.5);
  box-shadow: 0px 5px 40px 20px rgba(253, 81, 9, 0.68);
  z-index: 1;
}

.faded-dot-item-sm {
  height: 25px;
  width: 25px;
  position: absolute;
  left: -2px;
  top: -5px;
  background-color: rgba(253, 81, 9, 1);
  filter: blur(8px);
  -webkit-filter: blur(8px);
  border-radius: 50%;
  //box-shadow: 0px 5px 38px 30px rgba(253, 81, 9, 0.5);
  box-shadow: 0px 5px 20px 10px rgba(253, 81, 9, 0.68);
  z-index: 1;
}

.faded-dot-included {
  position: relative;
  display: inline-block;
  &::after {
    content: "";
    height: 25px;
    width: 25px;
    position: absolute;
    right: -15px;
    top: -5px;
    background-color: rgba(253, 81, 9, 1);
    filter: blur(8px);
    -webkit-filter: blur(8px);
    border-radius: 50%;
    //box-shadow: 0px 5px 38px 30px rgba(253, 81, 9, 0.5);
    box-shadow: 0px 5px 20px 10px rgba(253, 81, 9, 0.68);
    z-index: -1;
  }
}

.faded-dot-item-om {
  height: 50px;
  width: 50px;
  position: absolute;
  right: 50px;
  top: 50%;
  transform: translate(0, -50%);
  background-color: rgba(253, 81, 9, 1);
  filter: blur(8px);
  -webkit-filter: blur(8px);
  border-radius: 50%;
  //box-shadow: 0px 5px 38px 30px rgba(253, 81, 9, 0.5);
  box-shadow: 0px 5px 40px 20px rgba(253, 81, 9, 0.68);
  z-index: 1;
}

// dot
.dot {
  width: 24px;
    height: 24px;
}

// Center item
.center-item {
  transform: translate(-50%, -50%);
}

.transparent-bg {
  background-color: transparent !important;
}

// Cover images
.cover-image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

// Loading spinner on overlay middle of the screen
.loading-spinner-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  /* Semi-transparent white overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Adjust the z-index as needed */
}