.hoverable-bg {
  &:hover {
    background-color: $primary;

    & button {
      color: #fff !important;
    }
  }
}

// .btn.active.btn-light {

// }

.input-radio {
  border:  1px solid $gray-300;
  &.active {
    background-color: $primary;
    border:  1px solid $gray-300;
  }
}

.input-state {
  border:  1px solid $gray-300;
  &.active {
    background-color: $primary;
    border:  1px solid $gray-300;
  }
}

.input-checkbox {
  background-color: #fff;
}

label input[type="radio"] {
  visibility: hidden;
}

.ReactModal__Overlay {
  z-index: 1060;
}

.MuiSvgIcon-root.MuiStepIcon-root {
  &:hover {
    cursor: pointer; 
  }
}

.MuiTab-textColorPrimary.Mui-selected {
  color: $custom-primary-900
}